import React from "react"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import Hidden from "@material-ui/core/Hidden"

import EpicGames from "../../../static/clients/Epic_Games_logo.svg"
import EA from "../../../static/clients/Electronic-Arts-Logo.svg"
import Nokia from "../../../static/clients/Nokia_wordmark.svg"
import Samsung from "../../../static/clients/Samsung_Logo.svg"
import Alcatel from "../../../static/clients/Alcatel_Lucent_Logo.svg"
import Verizon from "../../../static/clients/Verizon_2015_logo_-vector.svg"
import AbbotHeader from "../../../static/clients/AbbottLaboratories.svg"
import BlizzardEntertainment from "../../../static/clients/Blizzard_Entertainment_Logo.svg"
import Citrix from "../../../static/clients/Citrix.svg"
import DirectTV from "../../../static/clients/DirecTV_logo.svg"
import Disney from "../../../static/clients/Disney_wordmark.svg"
import FrontierCommunications from "../../../static/clients/Frontier_Communications_Corporation_logo_2016.svg"
import HPNew from "../../../static/clients/HP_New_Logo_2D.svg"
import Intelerad from "../../../static/clients/intelerad.svg"
import KIK from "../../../static/clients/Kik_Messenger_logo.svg"
import MobileHeartBeat from "../../../static/clients/MobileHeartbeat Logo.png"
import Zoosk from "../../../static/clients/ZooskLogo.png"

import TagCloud from "react-tag-cloud"
import "./companies.css"

const PartnersItem = styled.div`
  font-size: 30px;
  background: transparent;
  opacity: 0.7;
  color: #1e4778;
  text-align: center;
  margin: 20px;
`

const CompaniesText = styled.h3`
  font-size: 32px;
  color: #3e4346;
  text-align: center;
  width: 100%;
  padding: 3%;
  @media screen and (max-width: 690px) {
    margin: 0px;
  }
`

const CompaniesOverviewContainer = styled.div`
  @media screen and (min-width: 690px) {
    margin: 80px;
    padding-bottom: 80px;
  }
`

class CompaniesOverview extends React.Component {
  componentDidMount() {
    setInterval(() => {
      this.forceUpdate()
    }, 6000)
  }

  render() {
    const SECTIONHEIGHT = 600

    const CLIENTS = [
      EpicGames,
      EA,
      Nokia,
      Samsung,
      Alcatel,
      Verizon,
      AbbotHeader,
      BlizzardEntertainment,
      Citrix,
      DirectTV,
      Disney,
      FrontierCommunications,
      HPNew,
      Intelerad,
      KIK,
      MobileHeartBeat,
      Zoosk,
    ]

    return (
      <CompaniesOverviewContainer>
        <CompaniesText>
          Over 4,200 Tigase servers running worldwide...
        </CompaniesText>
        <Hidden only={"xs"}>
          <div
            style={{
              display: "flex",
              position: "relative",
              height: SECTIONHEIGHT,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TagCloud
              className="tag-cloud"
              style={{
                padding: 5,
                width: "100%",
                height: "100%",
              }}
            >
              {CLIENTS.map((item, index) => {
                return (
                  <div
                    text={"item" + index}
                    style={{ height: 40, width: "auto" }}
                    key={index}
                  >
                    <img style={{ width: "auto", height: "100%" }} src={item} />
                  </div>
                )
              })}
            </TagCloud>
          </div>
        </Hidden>
        <Hidden smUp>
          <div
            style={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid container style={{ width: "100%" }}>
              {CLIENTS.map((item, index) => {
                return (
                  <Grid key={index} item xs={6}>
                    <PartnersItem>
                      <img style={{ width: "100%", height: 40 }} src={item} />
                    </PartnersItem>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </Hidden>
      </CompaniesOverviewContainer>
    )
  }
}

export default CompaniesOverview
