import React from "react"
import Link from "gatsby-link"

const LinkExternalInternal = props => {
  return (
    <>
      {props.to && props.to.startsWith("http") && (
        <a href={props.to}>{props.children}</a>
      )}
      {props.to && props.to.startsWith("/") && (
        <Link to={props.to} state={props.state}>
          {props.children}
        </Link>
      )}
    </>
  )
}

export default LinkExternalInternal
